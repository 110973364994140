import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import adminSelectors from 'src/redux/admin/admin-selectors';
import authSelectors from 'src/redux/auth/auth-selectors';
import { StoreState } from 'src/redux/store';
import { Role, User } from 'src/types/auth';
import { CRUD } from 'src/types/core';
import { CommunityMember } from 'src/types/forum';
import { CommunityRole } from 'src/types/organization';
import { useUserTransaction } from '../../Common/Hooks/useUserTransaction';
import { fullName } from '../../Common/utils';
import PillDropdown, { PillDropdownProps } from './PillDropdown';

interface UpdateCommunityRoleDropdownProps {
  member: User | CommunityMember;
  communityId?: number;
  // allow for someone to choose 'none', effectively removing the user
  // from the community by removing their community roles
  allowNone?: boolean;
}

const UpdateCommunityRoleDropdown = ({
  member,
  communityId,
  allowNone,
}: UpdateCommunityRoleDropdownProps) => {
  const iAmCommunityAdmin = useSelector((state: StoreState) =>
    adminSelectors.iAmCommunityAdmin(state, communityId)
  );

  const { t } = useTranslation();

  const { startTransaction, transactionLoading } = useUserTransaction();

  const activeCommunity = useSelector(adminSelectors.getActiveCommunity);
  const communities = useSelector(adminSelectors.getCommunities);

  const targetCommunityId = communityId || activeCommunity?.id;

  const roleFilter = (role: Role) => {
    return (
      role.community_id === targetCommunityId &&
      Object.values(CommunityRole)
        .map((role) => role.toLowerCase())
        .includes(role.role_type as CommunityRole)
    );
  };

  const getRolePriority = (role: Role) =>
    Object.values(CommunityRole)
      // remove host role and lowercase the rest
      .map((role) => role.toLowerCase())
      .indexOf(role.role_type as CommunityRole);

  const sortedCommunityRoles =
    member?.roles
      ?.filter(roleFilter)
      ?.sort((a, b) => getRolePriority(a) - getRolePriority(b)) ?? [];

  const communityRole = (sortedCommunityRoles[0]?.role_type ||
    'None') as CommunityRole;

  const isCommunityMember = communityRole.toLowerCase() !== 'none';

  const isCommunityOwner =
    communityRole.toLowerCase() === CommunityRole.owner.toLowerCase();

  const disabled =
    isCommunityOwner ||
    !iAmCommunityAdmin ||
    (!isCommunityMember && !allowNone);

  const handleAccessLevelChange = (updatedRole: CommunityRole | 'None') => {
    const role = updatedRole.toLocaleLowerCase();
    const name = fullName(member);
    const community = communities.find((c) => c.id === targetCommunityId);
    const communityName = community?.name;

    if (role === 'none') {
      startTransaction({
        type: 'remove_community_member',
        data: {
          id: member.id,
          communityId: targetCommunityId,
        },
        toast: {
          onError: {
            content: (err) =>
              t('admin.remove_community_member_error_toast', {
                name,
                communityName,
              }),
            options: {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: false,
            },
          },
          onSuccess: {
            content: t('admin.remove_community_member_success_toast', {
              name,
              communityName,
            }),
            options: {
              position: toast.POSITION.BOTTOM_RIGHT,
            },
          },
        },
      });
    } else if (!isCommunityMember) {
      startTransaction({
        type: 'add_community_member',
        data: {
          id: member.id,
          communityId: targetCommunityId,
          roles: [
            {
              role_type: role,
            },
          ],
        },
        toast: {
          onError: {
            content: (err) =>
              t('admin.add_community_member_error_toast', {
                name,
                communityName,
              }),
            options: {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: false,
            },
          },
          onSuccess: {
            content: t('admin.add_community_member_success_toast', {
              name,
              communityName,
            }),
            options: {
              position: toast.POSITION.BOTTOM_RIGHT,
            },
          },
        },
      });
    } else {
      startTransaction({
        type: 'update_user_community_role',
        crud: CRUD.put,
        data: {
          id: member.id,
          communityId: targetCommunityId,
          roles: [
            {
              role_type: role,
            },
          ],
        },
      });
    }
  };

  const testId = `member-${member.id}-access-level`;

  const viewProps: CommunityRoleDropdownViewProps = {
    communityRole,
    testId,
    disabled,
    allowNone,
    loading: transactionLoading,
    onSelect: handleAccessLevelChange,
  };

  return <CommunityRoleDropdown {...viewProps} />;
};

type CommunityRoleDropdownViewProps = {
  communityRole: CommunityRole | 'None';
  onSelect: (role: CommunityRole | 'None') => void;
  testId?: string;
  loading?: boolean;
  disabled?: boolean;
  allowNone?: boolean;
};

export const CommunityRoleDropdown = ({
  communityRole,
  testId,
  loading,
  disabled,
  onSelect,
  allowNone,
}: CommunityRoleDropdownViewProps) => {
  const user = useSelector(authSelectors.getUser);
  const USER_MANAGEMENT_FLAG = user.flags?.user_management_changes;

  const props: PillDropdownProps = {
    dropdownItems: [
      {
        value: CommunityRole.owner,
        hide: true,
      },
      {
        value: CommunityRole.admin,
        hide: false,
      },
      {
        value: CommunityRole.sensemaker,
        hide: USER_MANAGEMENT_FLAG ? true : false,
      },
      {
        value: CommunityRole.host,
        hide: false,
      },
      {
        value: CommunityRole.member,
        hide: false,
      },
      {
        value: 'None',
        hide: !allowNone && communityRole.toLowerCase() !== 'none',
      },
    ],
    selectedValue: communityRole,
    onSelect: onSelect as (val: string) => void,
    loading,
    disabled,
    testId,
  };

  return <PillDropdown {...props} />;
};

export default UpdateCommunityRoleDropdown;
