import React, { useContext, useMemo, useState } from 'react';
import { connect, DispatchProp } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';

import color from 'src/assets/_util.scss';
import { Dropdown } from 'src/components/core/Dropdown';
import { HSpacer } from 'src/components/core/Spacer';
import { HStack, VStack } from 'src/components/core/Stack';
import { View } from 'src/components/core/View';
import { useHighlightsPageContext } from 'src/components/Insights/Catalog/HighlightsPageProvider';
import { StatusItems } from 'src/components/Insights/Catalog/status';
import { Button, LinkButton } from 'src/components/Insights/Common/Button';
import CodeChip from 'src/components/Insights/Common/CodeChip';
import PlayButton from 'src/components/PlayButton/PlayButton';
import ScrollingTranscript from 'src/components/ScrollingTranscript/ScrollingTranscript';
import GlobalAudioContext, {
  useSeekAudio,
} from 'src/contexts/GlobalAudioContext';
import { useAnalyticsContext } from 'src/Providers/AnalyticsProvider';
import { selectors as catalogSelectors } from 'src/redux/catalog/catalog-selectors';
import {
  assignPrimarySpeaker,
  setEntriesStatus,
} from 'src/redux/catalog/catalog-slice';
import { StoreState } from 'src/redux/store';
import { Action, Category, Name } from 'src/types/analytics';
import { User } from 'src/types/auth';
import { Conversation } from 'src/types/conversation';
import {
  AIRequestStatus,
  Catalog,
  Entry,
  EntryStatus,
  Participant,
} from 'src/types/insights';
import {
  mapSeekTimeToConversation,
  mapSeekTimeToHighlight,
} from 'src/util/audio';
import { createBasicProvider } from 'src/util/provider';

interface BaseProps {
  entry: Entry;
  onClick?: () => void;
  onChangeCheck?: (checked: boolean, entry: Entry) => void;
  checked?: boolean;
  open?: boolean;
}

type Props = BaseProps & ReturnType<typeof mapStateToProps> & DispatchProp;

const mapStateToProps = (state: StoreState, props: BaseProps) => {
  const conversation = catalogSelectors.getConversationByEntry(
    state,
    props.entry
  );
  return {
    author: catalogSelectors.getAuthorForEntry(state, props.entry),
    codes: catalogSelectors.getCodeTagsForEntry(state, props.entry),
    conversation: conversation,
    demographics: catalogSelectors.getDemographicsForEntry(state, props.entry),
    snippets: catalogSelectors.getHighlightSnippets(
      state,
      props.entry.snippet_ids
    ),
    participants:
      conversation !== undefined
        ? catalogSelectors.getParticipantsForConversation(state, conversation)
        : [],
  };
};

function HighlightItem({
  author,
  codes,
  conversation,
  demographics,
  entry,
  onClick,
  open = false,
  participants,
  snippets,
  checked = false,
  onChangeCheck,
}: Props) {
  const highlightsPageContext = useHighlightsPageContext();
  const { pendingTagRequests, thematicCodes, aiOn } = highlightsPageContext;

  const suggestedTags = useMemo(
    () =>
      entry.suggested_codings.map((coding) => ({
        ...coding,
        ...thematicCodes.find((code) => code.id === coding.code_id)!,
      })),
    [entry, thematicCodes]
  );

  const { audio_start_offset, audio_end_offset, audio_fade_in_out = 1 } = entry;

  const adjustedStartTime = 0;
  const adjustedEndTime =
    audio_end_offset - audio_start_offset + 2 * audio_fade_in_out;
  const seekAudio = useSeekAudio({
    audioUrl: `${window.location.origin}/api/highlights/play/${entry.annotation_id}`,
    startTime: adjustedStartTime,
    endTime: adjustedEndTime,
    meta: conversation,
    id: `highlight-${entry.annotation_id}`,
  });
  const { isPlaying } = useContext(GlobalAudioContext);
  const { seekTime, activatedPlay } = seekAudio;

  const unappliedSuggestedTags = aiOn
    ? suggestedTags.filter((tag) => !codes.some((c) => c.id === tag.id))
    : [];
  const tags = [...demographics, ...codes, ...unappliedSuggestedTags];
  const hasTags = tags.length > 0;

  // useCallback needed so we don't constantly create new functions causing
  // scrolling transcript to re-render all the time
  const handlePlay = React.useCallback(
    (seekTime?: number) => {
      const adjustedSeekTime = mapSeekTimeToHighlight(
        seekTime,
        audio_start_offset,
        audio_fade_in_out
      );

      activatedPlay(
        seekTime == null ? audio_start_offset : adjustedSeekTime,
        audio_end_offset
      );
    },
    [audio_start_offset, audio_fade_in_out, activatedPlay, audio_end_offset]
  );

  const speakers = useMemo(
    () =>
      participants.filter((participant) =>
        (entry.participant_ids || []).includes(participant.id.toString())
      ),
    [participants, entry.participant_ids]
  );

  const awaitingSuggestion = pendingTagRequests.some(
    (req) =>
      req.annotation_id === entry.annotation_id &&
      req.ai_request_status === AIRequestStatus.pending
  );

  const padding = open ? 40 : 20;

  return (
    <LocalProvider
      value={{
        open,
        entry,
        conversation,
        author,
        speakers,
        checked,
        padding,
        seekAudio,
        awaitingSuggestion,
        onChangeCheck,
        ...highlightsPageContext,
      }}
    >
      <VStack
        data-testid={`HighlightCard${open ? '-open' : ''}`}
        key={entry.id}
        sx={{
          backgroundColor: color[checked ? 'foraLighterCustom' : 'gray100'],
          borderRadius: '24px',
          border: `1px solid ${color.gray300}`,
          cursor: open ? '' : 'pointer',
          position: 'relative',
          transition: 'all 200ms ease-out',
          overflow: 'auto',
          marginTop: open ? 'auto' : '',
          pointerEvents: 'auto',
          ':hover': {
            boxShadow: open ? '' : '0 0 12px rgba(0, 0, 0, 0.06)',
            transform: open ? '' : 'scale(1.008) translateY(-1px)',
            borderColor: open ? '' : color.gray500,
          },
        }}
      >
        <View
          // hit box. allows us to set a higher index on other clickable elements
          // thus preventing the highlight card from being opened when clicking on them
          style={{
            position: 'absolute',
            inset: 0,
            zIndex: 1,
            display: open ? 'none' : 'block',
          }}
          onClick={onClick}
        />

        <TitleBar />

        {entry.description && (
          <Typography
            data-testid="highlight_description"
            variant={open ? 'body1' : 'body2'}
            sx={{
              backgroundColor:
                color[checked ? 'foraPurpleCustomLighter' : 'gray300'],
              lineHeight: '21px',
              p: '8px 12px',
              m: `0 ${padding}px`,
              borderRadius: '8px',
              width: 'fit-content',
              maxWidth: '100%',
              wordBreak: 'break-word',
            }}
          >
            {entry.description}
          </Typography>
        )}

        <Player />

        <ScrollingTranscript
          snippets={snippets}
          seekTime={
            // Needed for proper dynamic highlighting
            isPlaying
              ? mapSeekTimeToConversation(
                  seekTime,
                  audio_start_offset,
                  audio_fade_in_out
                )
              : undefined
          }
          filterWordsEnd={audio_end_offset}
          filterWordsStart={audio_start_offset}
          onPlay={handlePlay}
          hideAdditionalInfo
          embedded={false}
          style={{
            zIndex: 2,
            border: 'none',
            borderRadius: 0,
            background: 'transparent',
            fontSize: open ? '20px' : '',
            overflowY: 'auto',
            minHeight: '108px',
            margin: open ? `24px ${padding}px` : `16px ${padding}px`,
          }}
          innerStyle={{
            maxHeight: 'unset',
          }}
        />

        {hasTags && (
          <HStack
            sx={{
              flexWrap: 'wrap',
              p: `${open ? 18 : 10}px ${padding}px ${open ? 0 : padding}px`,
            }}
          >
            {tags.map((tag) => (
              <View component="span" key={tag.id} sx={{ mr: '6px', mt: '6px' }}>
                <CodeChip
                  code={tag}
                  noCount
                  suggested={suggestedTags.some((t) => t.code_id === tag.id)}
                  selected={codes.some((c) => c.id === tag.id)}
                />
              </View>
            ))}
          </HStack>
        )}

        {open && <ActionBar />}
      </VStack>
    </LocalProvider>
  );
}

export default connect(mapStateToProps)(React.memo(HighlightItem));

// SUBCOMPONENTS

function TitleBar() {
  const {
    open,
    entry,
    author,
    speakers,
    checked,
    padding,
    awaitingSuggestion,
    onChangeCheck,
    t,
  } = useLocalContext();

  const hasStatusBadge =
    entry.status && entry.status !== EntryStatus.ToDo && !open;

  return (
    <HStack
      sx={{
        alignItems: 'center',
        p: `${padding}px ${padding}px ${open ? '28px' : '12px'}`,
      }}
    >
      {!open &&
        (awaitingSuggestion ? (
          <CircularProgress size={16} style={{ margin: '0 8px 0 0' }} />
        ) : (
          <Checkbox
            size="small"
            style={{
              color: color[checked ? 'foraPurple' : 'gray650'],
              marginTop: '-9px',
              marginBottom: '-9px',
              marginLeft: '-9px',
            }}
            checked={checked}
            onChange={(e, checked) => onChangeCheck?.(checked, entry)}
            data-testid="highlight-card-checkbox"
          />
        ))}

      <View
        sx={{
          mr: '12px',
          gap: '4px',
          display: 'flex',
          flexDirection: open ? 'column' : 'row',
        }}
      >
        <Typography
          variant={open ? 'body1' : 'body2'}
          color={color.gray700}
          data-testid="highlight_id"
          sx={{ fontSize: open ? '18px' : '' }}
        >
          #{entry.annotation_id}
        </Typography>

        <Typography
          data-testid="highlight_author"
          variant={open ? 'body1' : 'body2'}
          sx={{ fontSize: open ? '18px' : '' }}
        >
          {t('common.by')}{' '}
          <span style={{ fontWeight: 600 }}>
            {author?.user_name ?? t('insights.unknown')}
          </span>
        </Typography>
      </View>

      <HSpacer grow />

      {open && (
        <HStack sx={{ gap: '20px' }}>
          {speakers.length > 1 && <PrimarySpeakerPicker />}
          <StatusPicker />
        </HStack>
      )}

      {hasStatusBadge && entry.status && (
        <Typography
          variant="body2"
          sx={{
            border: `1px solid ${StatusItems[entry.status].color}`,
            textOverflow: 'ellipsis',
            flexShrink: 1,
            overflow: 'hidden',
            color: StatusItems[entry.status].color,
            ml: 'auto',
            px: '12px',
            py: '3px',
            lineHeight: '18px',
            fontSize: '13px',
            borderRadius: '8px',
          }}
        >
          {t(`insights.highlight_status.${entry.status}`)}
        </Typography>
      )}
    </HStack>
  );
}

function Player() {
  const { open, padding, conversation, speakers, seekAudio } =
    useLocalContext();
  const { isLoading, isPlaying, audioError } = useContext(GlobalAudioContext);
  const { isActive, toggleActivatedPlaying } = seekAudio;

  return (
    <HStack
      sx={{
        gap: '16px',
        position: 'relative',
        margin: open
          ? `28px ${padding}px 24px`
          : `${padding}px ${padding}px 16px`,
      }}
    >
      <PlayButton
        isPlaying={isActive && isPlaying}
        isLoading={isActive && isLoading}
        isError={isActive && audioError != null}
        onClick={toggleActivatedPlaying}
        shape={'circle'}
        size="lg"
        style={{
          zIndex: 2,
          flexShrink: 0,
          flexBasis: `${open ? 60 : 48}px`,
          height: `${open ? 60 : 48}px`,
        }}
      />

      <VStack>
        <Typography sx={{ fontWeight: '700', fontSize: `${open ? 20 : 16}px` }}>
          {speakers.map((s) => s.name).join(', ')}
        </Typography>

        {conversation && (
          <Typography
            data-testid="conversation_title"
            sx={{ fontSize: `${open ? 20 : 16}px` }}
          >
            {conversation.title}
          </Typography>
        )}
      </VStack>
    </HStack>
  );
}

function ActionBar() {
  const {
    catalog,
    entry,
    conversations,
    padding,
    awaitingSuggestion,
    canTagWithAI,
    tagWithAI,
    t,
  } = useLocalContext();

  const { analyticsEvent } = useAnalyticsContext();
  const conversationTitle =
    conversations.find((c) => c.id === entry.conversation_id)?.title ?? '';
  const downloadFilename = `Fora ${conversationTitle} - highlight:${entry.annotation_id}`;
  const downloadHref = `${window.location.origin}/api/highlights/play/${entry.annotation_id}`;
  const handleDownload = () => {
    analyticsEvent({
      category: Category.Highlight,
      action: Action.Download,
      name: Name.HighlightCard,
    });
  };
  return (
    <HStack sx={{ m: `${padding}px` }}>
      {canTagWithAI && (
        <Button
          tKey={
            awaitingSuggestion
              ? 'insights.suggestingCodesWithAI'
              : 'insights.suggestCodesWithAI'
          }
          onClick={() => tagWithAI([entry.id])}
          busy={awaitingSuggestion}
        />
      )}
      <HSpacer grow />
      <Button
        component={'a'}
        href={downloadHref}
        onClick={handleDownload}
        //@ts-ignore - MUI doesn't adjust to the attributes of the component
        download={downloadFilename}
      >
        <FontAwesomeIcon icon={['far', 'arrow-to-bottom']} />
      </Button>
      {!catalog.mobile && (
        <LinkButton
          data-testid="ViewHighlightLink"
          tKey="insights.highlight_card_view_highlight"
          href={`/highlight/${entry.annotation_id}`}
        />
      )}
    </HStack>
  );
}

function PrimarySpeakerPicker() {
  const { speakers, entry, catalog, dispatch, t } = useLocalContext();

  const speakerItems = speakers.map((speaker) => ({
    ...speaker,
    label: speaker.name,
  }));
  const selectedSpeaker = speakerItems.find(
    (speaker) => speaker.id === entry.primary_participant_id
  );

  return (
    <Dropdown
      items={speakerItems}
      initialSelection={selectedSpeaker}
      placeholder={{ label: t('insights.unknown', 'Unknown') }}
      label="insights.highlight_card_primary_speaker"
      onSelect={(item) => {
        dispatch(assignPrimarySpeaker([catalog.id, entry.id, item.id]));
      }}
      style={{ zIndex: 2 }}
    />
  );
}

function StatusPicker() {
  const { entry, catalog, dispatch, t } = useLocalContext();
  const [selectedStatus, setSelectedStatus] = useState(entry.status);

  return (
    <Dropdown
      items={StatusItems}
      initialSelection={StatusItems.find((item) => item.value === entry.status)}
      placeholder={{ label: t('insights.highlight_status.to-do') }}
      label="common.status"
      onSelect={(item) => {
        setSelectedStatus(item.value);
        dispatch(setEntriesStatus([catalog.id, [entry.id], item.value]));
      }}
      color={selectedStatus && StatusItems[selectedStatus].color}
      style={{ zIndex: 10 }}
    />
  );
}

// LOCAL CONTEXT

const [LocalProvider, useLocalContext] = createBasicProvider<
  {
    open: boolean;
    catalog: Catalog;
    entry: Entry;
    conversation?: Conversation;
    author: User;
    speakers: Participant[];
    checked: boolean;
    padding: number;
    seekAudio: ReturnType<typeof useSeekAudio>;
    awaitingSuggestion: boolean;
    onChangeCheck?: (checked: boolean, entry: Entry) => void;
  } & ReturnType<typeof useHighlightsPageContext>
>();
