import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { useAIContext } from 'src/components/Insights/Catalog/AIProvider';
import { useCatalogPageContext } from 'src/components/Insights/Catalog/CatalogPageProvider';
import { useCodebookContext } from 'src/components/Insights/Catalog/CodebookProvider';
import { TaggingBannerDetails } from 'src/components/Insights/Catalog/TaggingBannerDetails';
import catalogSelectors from 'src/redux/catalog/catalog-selectors';
import { Entry, Filter, FilterJoin } from 'src/types/insights';
import { createProvider } from 'src/util/provider';
import { useDelayedEffect } from 'src/util/useDelayed';

export const [HighlightsPageProvider, useHighlightsPageContext] =
  createProvider(() => {
    const catalogPageContext = useCatalogPageContext();
    const { entries, t } = catalogPageContext;

    const aiContext = useAIContext();
    const { taggingProgress } = aiContext;

    const [currentPage, setCurrentPage] = useState(1);
    const [selectedHighlightIds, setSelectedHighlightIds] = useState(
      new Set<Entry['id']>()
    );
    const [banners, setBanners] = useState<BannerItem[]>([]);

    // FE pagination - currently not true pagination, but kept as an aesthetic choice.
    // True pagination exists for catalogs with more than 10,000 entries; see sagaLoadCatalog to change that value.
    const pages = useMemo(() => _.chunk(entries, HighlightsPerPage), [entries]);
    const visibleHighlights = useMemo(
      () => pages[currentPage - 1],
      [pages, currentPage]
    );

    // ••• FILTER VALUES & Actions

    const selectedFilters = useSelector(catalogSelectors.getSelectedFilters);
    const initialFilter = useSelector(catalogSelectors.getInitialFilter);
    const selectedFilterJoin = useSelector(catalogSelectors.getFilterJoin);
    const [openFilters, setOpenFilters] = useState(false);
    const [filters, setFilters] = useState<Filter[]>([...selectedFilters]);
    const [filterJoin, setFilterJoin] =
      useState<FilterJoin>(selectedFilterJoin);
    const resetFilters = () => setFilters([...selectedFilters]);

    // ••• BANNER ACTIONS

    const showBanner = (
      props: Partial<BannerItem> & Pick<BannerItem, 'message'>
    ) => {
      const banner = {
        ...props,
        id: props.id ?? `${Math.random()}`,
      };
      setBanners((prev) => [banner, ...prev]);
      return banner;
    };

    const updateBanner = (
      updatedProps: Partial<BannerItem> & Pick<BannerItem, 'id'>
    ) => {
      setBanners((prev) => {
        const bannerIndex = prev.findIndex((b) => b.id === updatedProps.id);
        if (bannerIndex == -1) return prev;

        const newBanners = [...prev];
        newBanners[bannerIndex] = {
          ...newBanners[bannerIndex],
          ...updatedProps,
        };
        return newBanners;
      });
    };

    const hideBanner = (id: BannerItem['id']) => {
      setBanners((prev) => prev.filter((b) => b.id !== id));
    };

    // ••• TAGGING BANNER

    useEffect(() => {
      const { percent, totalCount } = taggingProgress;
      if (!totalCount) return;

      const bannerProps = {
        id: TaggingBannerId,
        message: t(
          percent === 100
            ? 'insights.taggingProgressBannerDone'
            : 'insights.taggingProgressBanner',
          { count: totalCount }
        ),
        details: <TaggingBannerDetails />,
        progress: percent,
      };

      const bannerVisible = banners.some((b) => b.id === TaggingBannerId);
      if (bannerVisible) {
        updateBanner(bannerProps);
      } else if (percent < 100) {
        showBanner(bannerProps);
      }
    }, [taggingProgress]);

    useDelayedEffect(
      () => {
        if (taggingProgress.percent === 100 || !taggingProgress.totalCount)
          hideBanner(TaggingBannerId);
      },
      8000,
      [taggingProgress]
    );

    useEffect(() => {
      // Always ensure that a change in the applied filters updates the UI filters
      resetFilters();
    }, [selectedFilters]);

    // ••• CONTEXT

    return {
      ...catalogPageContext,
      ...aiContext,
      ...useCodebookContext(),

      currentPage,
      setCurrentPage,

      visibleHighlights,
      allHighlights: entries,
      selectedHighlightIds,
      setSelectedHighlightIds,
      clearSelection: () => setSelectedHighlightIds(new Set()),

      openFilters,
      setOpenFilters,
      selectedFilterJoin,
      filterJoin,
      setFilterJoin,
      initialFilter,
      selectedFilters,
      filters,
      setFilters,
      resetFilters,

      banners,
      showBanner,
      updateBanner,
      hideBanner,
    };
  });

// DEFINITIONS

export const HighlightsPerPage = 20;
const TaggingBannerId = 'taggingBanner';

export type BannerItem = {
  id: string;
  message: string;
  duration?: number;
  details?: ReactElement;

  /** percent: 0 - 100 */
  progress?: number;
};
