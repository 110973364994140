import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import catalogSelectors from 'src/redux/catalog/catalog-selectors';
import {
  FilterJoin,
  FilterName,
  FilterOperation,
  FilterOperationMap,
  FilterOption,
  FilterOptions,
} from 'src/types/insights';

export const useFilterLabels = () => {
  const { t } = useTranslation();

  const filterJoinLabel: Record<FilterJoin, string> = useMemo(
    () => ({
      [FilterJoin.AND]: t('insights.filter_join_and'),
      [FilterJoin.OR]: t('insights.filter_join_or'),
    }),
    []
  );

  const filterCategoryLabel: Record<FilterName, string> = useMemo(
    () => ({
      [FilterName.APPLIED_CODES]: t('insights.filter_name_applied'),
      [FilterName.AI_SUGGESTED_CODES]: t('insights.filter_name_ai'),
      [FilterName.APPLIED_CODES_BY_USER]: t('insights.filter_name_applier'),
      [FilterName.CONVERSATIONS]: t('insights.filter_name_conversation'),
      [FilterName.HIGHLIGHTED_BY_USER]: t('insights.filter_name_highlighter'),
      [FilterName.HIGHLIGHT_STATUS]: t('insights.filter_name_status'),
      [FilterName.SPEAKER_NAME]: t('insights.filter_name_speaker'),
      [FilterName.SPEAKER_DEMOGRAPHICS]: t('insights.filter_name_demographics'),
      // [FilterType.ASSIGNED_TO]: t("insights.filter_name_assignee"),
    }),
    []
  );

  const filterOperationsLabel: Record<FilterOperation, string> = useMemo(
    () => ({
      [FilterOperation.HAS_ALL]: t('insights.filter_operation_has_all'),
      [FilterOperation.HAS_ANY]: t('insights.filter_operation_has_any'),
      [FilterOperation.HAS_NONE]: t('insights.filter_operation_has_none'),
      [FilterOperation.IS_EXACT]: t('insights.filter_operation_exact'),
      [FilterOperation.IS_EMPTY]: t('insights.filter_operation_empty'),
      [FilterOperation.IS_NOT_EMPTY]: t('insights.filter_operation_not_empty'),
      [FilterOperation.IS_ANY]: t('insights.filter_operation_is_any'),
      [FilterOperation.IS_NONE]: t('insights.filter_operation_is_none'),
    }),
    []
  );

  const filterValueLabel: Record<FilterName, string> = useMemo(
    () => ({
      [FilterName.APPLIED_CODES]: t('insights.filter_prompt_applied'),
      [FilterName.AI_SUGGESTED_CODES]: t('insights.filter_prompt_ai'),
      [FilterName.APPLIED_CODES_BY_USER]: t('insights.filter_prompt_applier'),
      [FilterName.CONVERSATIONS]: t('insights.filter_prompt_conversation'),
      [FilterName.HIGHLIGHTED_BY_USER]: t('insights.filter_prompt_highlighter'),
      [FilterName.HIGHLIGHT_STATUS]: t('insights.filter_prompt_status'),
      [FilterName.SPEAKER_NAME]: t('insights.filter_prompt_speaker'),
      [FilterName.SPEAKER_DEMOGRAPHICS]: t(
        'insights.filter_prompt_demographics'
      ),
      // [FilterType.ASSIGNED_TO]: t("insights.filter_prompt_assignee"),
    }),
    []
  );

  return useMemo(
    () => ({
      filterJoinLabel,
      filterCategoryLabel,
      filterOperationsLabel,
      filterValueLabel,
    }),
    [
      filterOperationsLabel,
      filterJoinLabel,
      filterCategoryLabel,
      filterValueLabel,
    ]
  );
};

export const valuelessOperations = [
  FilterOperation.IS_EMPTY,
  FilterOperation.IS_NOT_EMPTY,
];

export const useFilterOptions = (): FilterOptions => {
  const filters = useSelector(catalogSelectors.getFilters);
  const demographic_codes = useSelector(catalogSelectors.getDemographics);
  const allCodes = useSelector(catalogSelectors.getAllCodes);
  const mappedFilters = {} as FilterOptions;
  filters.forEach(
    (filter) => (mappedFilters[filter.filter_name] = filter.filter_values)
  );
  const appliedCodes: FilterOption[] = [...allCodes];
  const codeApplier: FilterOption[] =
    mappedFilters[FilterName.APPLIED_CODES_BY_USER] ?? [];
  const demographics: FilterOption[] = demographic_codes ?? [];
  const conversations: FilterOption[] =
    mappedFilters[FilterName.CONVERSATIONS] ?? [];
  const aiCodes: FilterOption[] = [...allCodes];
  const highlightStatus: FilterOption[] =
    mappedFilters[FilterName.HIGHLIGHT_STATUS] ?? [];
  const speakers: FilterOption[] = mappedFilters[FilterName.SPEAKER_NAME] ?? [];
  const highlighters: FilterOption[] =
    mappedFilters[FilterName.HIGHLIGHTED_BY_USER] ?? [];
  return useMemo(
    () => ({
      [FilterName.APPLIED_CODES]: appliedCodes,
      [FilterName.AI_SUGGESTED_CODES]: aiCodes,
      [FilterName.APPLIED_CODES_BY_USER]: codeApplier,
      [FilterName.CONVERSATIONS]: conversations,
      [FilterName.HIGHLIGHT_STATUS]: highlightStatus,
      [FilterName.SPEAKER_NAME]: speakers,
      [FilterName.SPEAKER_DEMOGRAPHICS]: demographics,
      [FilterName.HIGHLIGHTED_BY_USER]: highlighters,
      // [FilterType.ASSIGNED_TO]: [],
    }),
    [
      aiCodes,
      codeApplier,
      appliedCodes,
      conversations,
      highlightStatus,
      highlighters,
      speakers,
      demographics,
    ]
  );
};

export const useFilterOperations = (): FilterOperationMap => {
  const filters = useSelector(catalogSelectors.getFilters);
  const mappedFilterOperations = {} as FilterOperationMap;
  filters.forEach(
    (filter) => (mappedFilterOperations[filter.filter_name] = filter.operations)
  );
  const appliedCodes: FilterOperation[] =
    mappedFilterOperations[FilterName.APPLIED_CODES] ?? [];
  const codeApplier: FilterOperation[] =
    mappedFilterOperations[FilterName.APPLIED_CODES_BY_USER] ?? [];
  const demographics: FilterOperation[] =
    mappedFilterOperations[FilterName.SPEAKER_DEMOGRAPHICS] ?? [];
  const conversations: FilterOperation[] =
    mappedFilterOperations[FilterName.CONVERSATIONS] ?? [];
  const aiCodes: FilterOperation[] =
    mappedFilterOperations[FilterName.AI_SUGGESTED_CODES] ?? [];
  const highlightStatus: FilterOperation[] =
    mappedFilterOperations[FilterName.HIGHLIGHT_STATUS] ?? [];
  const speakers: FilterOperation[] =
    mappedFilterOperations[FilterName.SPEAKER_NAME] ?? [];
  const highlighters: FilterOperation[] =
    mappedFilterOperations[FilterName.HIGHLIGHTED_BY_USER] ?? [];
  return useMemo(
    () => ({
      [FilterName.APPLIED_CODES]: appliedCodes,
      [FilterName.AI_SUGGESTED_CODES]: aiCodes,
      [FilterName.CONVERSATIONS]: conversations,
      [FilterName.APPLIED_CODES_BY_USER]: codeApplier,
      [FilterName.HIGHLIGHT_STATUS]: highlightStatus,
      [FilterName.SPEAKER_NAME]: speakers,
      [FilterName.SPEAKER_DEMOGRAPHICS]: demographics,
      [FilterName.HIGHLIGHTED_BY_USER]: highlighters,
      // [FilterType.ASSIGNED_TO]: [],
    }),
    [
      aiCodes,
      codeApplier,
      appliedCodes,
      conversations,
      highlightStatus,
      highlighters,
      speakers,
      demographics,
    ]
  );
};
