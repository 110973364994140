import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import {
  ConsumeEvents,
  Table,
  TableProps,
} from 'src/components/core/Table/Table';
import { CommunityMember } from 'src/types/forum';
import { CommunityRole } from 'src/types/organization';
import { createBasicProvider } from 'src/util/provider';
import NameColumnContent from '../../Common/TableColumnContent/NameColumnContent';
import { CommunityRoleDropdown } from '../../OrganizationMembers/PillDropdowns/CommunityRoleDropdown';
import CommunityRoleTooltip from '../../OrganizationMembers/Tooltips/CommunityRoleTooltip';
import AddCommunityMemberButton from './AddCommunityMemberButton';

type RowState = {
  member: CommunityMember;
  role: CommunityRole;
};

const [LocalProvider, LocalContext] = createBasicProvider<{
  onRoleChanged: (memberId: CommunityMember['id'], role: CommunityRole) => void;
}>();

const RoleContent = ({ val: { member, role } }: { val: RowState }) => {
  const { onRoleChanged } = LocalContext();

  const onSelect = useCallback(
    // casting here as we aren't passing `allowNone` to `CommunityRoleDropdown`
    (role: string) => onRoleChanged(member.id, role as CommunityRole),
    [member.id, onRoleChanged]
  );

  return <CommunityRoleDropdown communityRole={role} onSelect={onSelect} />;
};

const ActionContent = ({ val: { member, role } }: { val: RowState }) => {
  return (
    <ConsumeEvents>
      <AddCommunityMemberButton member={member} role={role} />
    </ConsumeEvents>
  );
};

const NameContent = ({ val: { member } }: { val: RowState }) => {
  return <NameColumnContent val={member} />;
};

const CommunityRoleColumnLabel = () => {
  const { t } = useTranslation();
  const label = t('admin.add_community_member_table_role_label');
  return (
    <div className="d-flex">
      {label}
      <CommunityRoleTooltip width="300px" />
    </div>
  );
};

const AddCommunityMemberTable = ({ values }: { values: CommunityMember[] }) => {
  const { t } = useTranslation();
  const [, setQuery] = useQueryParams({
    userId: NumberParam,
    page: StringParam,
  });

  const [rows, setRows] = useState<RowState[]>([]);
  // need this to persist local changes as filtering/adding users changes `values`
  const [updatedUsers, setUpdatedUsers] = useState<
    Map<CommunityMember['id'], CommunityRole>
  >(new Map());

  // need this to honor filtering changes that affect `values`
  useEffect(() => {
    setRows(
      values.map((m) => {
        return {
          member: m,
          role: updatedUsers.get(m.id) || CommunityRole.member,
        };
      })
    );
  }, [values, setRows, updatedUsers]);

  const goToUser = (state: RowState) => {
    setQuery({ userId: state.member.id, page: 'view' }, 'pushIn');
  };

  const onRoleChanged = useCallback(
    (memberId: CommunityMember['id'], role: CommunityRole) => {
      const updatedRows = rows.map((r) => {
        if (r.member.id === memberId) {
          return {
            member: r.member,
            role,
          };
        }

        return r;
      });

      setUpdatedUsers(new Map(updatedUsers).set(memberId, role));
      setRows(updatedRows);
    },
    [rows, setRows, setUpdatedUsers, updatedUsers]
  );

  const tableProps: TableProps<RowState> = {
    values: rows,
    valToKey: (val) => val.member.id,
    onRowClick: goToUser,
    testId: 'add-community-member-table',
    columns: [
      {
        id: 'name',
        headerLabel: t('admin.add_community_member_table_name_label'),
        content: NameContent,
      },
      {
        id: 'role',
        widthFraction: 4,
        headerLabel: CommunityRoleColumnLabel,
        content: RoleContent,
      },
      {
        id: 'action',
        alignRight: true,
        widthFraction: 2,
        headerLabel: '',
        content: ActionContent,
      },
    ],
  };

  return (
    <LocalProvider value={{ onRoleChanged }}>
      <Table {...tableProps} />
    </LocalProvider>
  );
};

export default AddCommunityMemberTable;
