import { useSelector } from 'react-redux';

import adminSelectors from 'src/redux/admin/admin-selectors';
import { User } from 'src/types/auth';
import { CRUD } from 'src/types/core';
import { CommunityMember } from 'src/types/forum';
import { OrganizationRole } from 'src/types/organization';
import { useUserTransaction } from '../../Common/Hooks/useUserTransaction';
import PillDropdown, { PillDropdownProps } from './PillDropdown';

interface UpdateOrganizationRoleDropdownProps {
  member: User | CommunityMember;
}

const UpdateOrganizationRoleDropdown = ({
  member,
}: UpdateOrganizationRoleDropdownProps) => {
  const organizationId = useSelector(adminSelectors.getOrganizationId);
  const iAmAdminOrOwner = useSelector(adminSelectors.iAmAdminOrOwner);

  const { transactionLoading, startTransaction } = useUserTransaction();

  let organizationRole = (member.roles.filter(
    (role) => !!role.organization_id
  )[0]?.role_type || 'member') as OrganizationRole;

  // forcing all sensemaker roles to just be members while we wait for BE
  // to remove all references to org sensemakers
  if (
    organizationRole.toLowerCase() === OrganizationRole.sensemaker.toLowerCase()
  ) {
    organizationRole = OrganizationRole.member;
  }

  const handleAccessLevelChange = (organizationRole: OrganizationRole) => {
    startTransaction({
      type: 'update_user_organization_role',
      crud: CRUD.put,
      data: {
        id: member.id,
        roles:
          organizationRole === OrganizationRole.member
            ? []
            : [
                {
                  role_type: organizationRole.toLowerCase(),
                  organization_id: organizationId,
                },
              ],
      },
    });
  };

  const isOwner =
    organizationRole.toLowerCase() === OrganizationRole.owner.toLowerCase();

  const disabled = isOwner || !iAmAdminOrOwner;

  const testId = `member-${member.id}-access-level`;

  const viewProps: OrganizationRoleDropdownViewProps = {
    organizationRole,
    testId,
    disabled,
    loading: transactionLoading,
    onSelect: handleAccessLevelChange,
  };

  return <OrganizationRoleDropdown {...viewProps} />;
};

type OrganizationRoleDropdownViewProps = {
  organizationRole: OrganizationRole;
  onSelect: (role: OrganizationRole) => void;
  testId: string;
  loading?: boolean;
  disabled?: boolean;
};

export const OrganizationRoleDropdown = ({
  organizationRole,
  testId,
  loading,
  disabled,
  onSelect,
}: OrganizationRoleDropdownViewProps) => {
  const props: PillDropdownProps = {
    dropdownItems: [
      {
        value: OrganizationRole.owner,
        hide: true,
      },
      {
        value: OrganizationRole.admin,
        hide: false,
      },
      {
        value: OrganizationRole.member,
        hide: false,
      },
    ],
    selectedValue: organizationRole,
    onSelect: onSelect as (val: string) => void,
    loading,
    disabled,
    testId,
  };

  return <PillDropdown {...props} />;
};

export default UpdateOrganizationRoleDropdown;
