import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, DispatchProp } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { Catalog } from 'src/types/insights';

import styles from './CatalogCard.module.scss';

interface BaseProps {
  catalog: Catalog;
}

type Props = BaseProps & DispatchProp;

const CatalogCard: React.FunctionComponent<Props> = (props: Props) => {
  const { catalog } = props;
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Card
      elevation={3}
      key={catalog.id}
      className={styles.editCatalogCard}
      data-testid="EditCatalogCard"
      onClick={() => {
        history.push(`/insights/catalog/${catalog.id}`);
      }}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '5px',
        cursor: 'pointer',
      }}
    >
      <CardHeader
        title={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
              gap: '5px',
            }}
          >
            {catalog.mobile && (
              <Tooltip
                arrow
                data-testid="mobile-catalog-tooltip"
                placement="right"
                sx={{
                  color: '#fff',
                  backgroundColor: '#4F29B7',
                }}
                title={t('insights.mobile_catalog_tooltip')}
              >
                <Chip
                  component="span"
                  data-testid="mobile-catalog-chip"
                  label={t('insights.mobile_catalog')}
                  size="small"
                  sx={{
                    background: 'linear-gradient(to right, #4A6AD1, #6718CA)',
                    color: '#fff',
                    width: 'min-content',
                  }}
                  variant="outlined"
                />
              </Tooltip>
            )}
            <Typography component="h3" variant="h5">
              {catalog.title}
            </Typography>
          </div>
        }
      />
      <CardContent sx={{ pb: 3 }}>
        <Typography component="p">{catalog.description}</Typography>
      </CardContent>
    </Card>
  );
};

export default connect()(CatalogCard);
