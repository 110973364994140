import Fuse from 'fuse.js';
import _ from 'lodash';

import { sortCodes } from 'src/components/Insights/utils/code';
import { valuelessOperations } from 'src/components/Insights/utils/filters';
import { StoreState } from 'src/redux/store';
import { User } from 'src/types/auth';
import { Conversation, Snippet } from 'src/types/conversation';
import {
  AbstractCode,
  Catalog,
  CatalogFilter,
  Code,
  CodeTag,
  Demographic,
  Entry,
  Filter,
  FilterJoin,
  FilterName,
  FilterOperation,
  Participant,
} from 'src/types/insights';
import { OrganizationRole } from 'src/types/organization';
import { selectors as catalogFilterSelectors } from '../catalog-filters/catalog-filters-selectors';
import {
  ADMINISTRATIVE_CODES_ID,
  DisplayMode,
  EXCLUDE_FROM_PORTAL_CODE_ID,
  LoadingMode,
} from './catalog-slice';

export const selectors = {
  getAuthors: (state: StoreState): User[] => {
    return Object.values(state.catalog.authors);
  },
  getAuthorForEntry: (state: StoreState, entry: Entry): User => {
    return state.catalog.authors[entry.user_id];
  },
  getCatalog: (state: StoreState): Catalog => {
    return state.catalog.catalog;
  },
  getCodebook: (state: StoreState) => {
    return state.catalog.codebook;
  },
  getCodeCount: (state: StoreState, code: AbstractCode): number => {
    if (code.code_type === 'demographic') {
      return state.catalog.demographicCounts[code.id]?.count || 0;
    } else {
      return state.catalog.codeCounts[code.id]?.count || 0;
    }
  },
  getCodeTagsForEntry: (state: StoreState, entry: Entry): CodeTag[] => {
    return (
      entry.codings?.map((coding) => {
        const code = state.catalog.codes[coding.code_id];
        return {
          ...code,
          ...coding,
        };
      }) ?? []
    );
  },
  getConversations: (state: StoreState): Conversation[] => {
    return Object.values(state.catalog.conversations);
  },
  getConversationByEntry: (state: StoreState, entry: Entry) => {
    return Object.values(state.catalog.conversations).find((conversation) => {
      return conversation.id === entry.conversation_id;
    });
  },
  getDemographics: (state: StoreState): Demographic[] => {
    return sortCodes(Object.values(state.catalog.demographics));
  },
  getDemographicsForEntry: (state: StoreState, entry: Entry): Demographic[] => {
    const codes: Demographic[] = [];
    (entry.participant_ids || []).map((id) => {
      const participant: Participant | undefined =
        state.catalog.participants[`${id}`];
      if (participant !== undefined) {
        participant.demographic_ids.map((id) => {
          const code: Demographic | undefined =
            state.catalog.demographics[`${id}`];
          if (code !== undefined) {
            codes.push(code);
          }
        });
      }
    });
    return codes;
  },
  getDemographicsForParticipants: (
    state: StoreState,
    participants: Participant[]
  ) => {
    const demographics: { [key: Participant['id']]: Demographic[] } = {};
    participants.map((participant) => {
      demographics[participant.id] = participant.demographic_ids
        .map((id) => {
          return state.catalog.demographics[id];
        })
        .filter((demographic) => demographic !== undefined);
    });
    return demographics;
  },
  getEntries: (state: StoreState): Entry[] => {
    const [codesToShow, codesToHide] =
      catalogFilterSelectors.getCodeVisibility(state);
    const [conversationsToShow, conversationsToHide] =
      catalogFilterSelectors.getConversationVisibility(state);
    const [demographicsToShow, demographicsToHide] =
      catalogFilterSelectors.getDemographicVisibility(state);
    const [highlightersToShow, highlightersToHide] =
      catalogFilterSelectors.getHighlighterVisibility(state);
    const [participantsToShow] =
      catalogFilterSelectors.getParticipantVisibility(state);
    const transcriptSearch = catalogFilterSelectors.getTranscriptSearch(state);
    let entries = Object.values(state.catalog.entries);
    if (transcriptSearch.length) {
      const fuse = new Fuse(entries, {
        ignoreLocation: true,
        includeScore: true,
        keys: ['content'],
        threshold: 0.3,
      });
      entries = fuse.search(transcriptSearch).map((result) => {
        return result.item;
      });
    }
    return entries.filter((entry) => {
      // conversations
      if (
        conversationsToShow.length &&
        conversationsToShow.indexOf(entry.conversation_id) === -1
      ) {
        return false;
      } else if (
        conversationsToHide.length &&
        conversationsToHide.indexOf(entry.conversation_id) !== -1
      ) {
        return false;
      }
      // highlighters
      if (
        highlightersToShow.length &&
        highlightersToShow.indexOf(entry.user_id) === -1
      ) {
        return false;
      } else if (
        highlightersToHide.length &&
        highlightersToHide.indexOf(entry.user_id) !== -1
      ) {
        return false;
      }
      /**
       * Participants
       *
       * If the intersection of an entry's participant_ids array and the participantsToShow array is empty, that means the highlight doesn't include any
       * of the desired participants, and should be filtered out.
       */
      if (
        participantsToShow.length &&
        _.intersection(
          entry.participant_ids,
          participantsToShow.map((participantId) => participantId.toString())
        ).length === 0
      ) {
        return false;
      }

      // codes
      if (
        codesToShow.length &&
        !entry.code_ids.filter((id) => codesToShow.indexOf(id) !== -1).length
      ) {
        return false;
      } else if (
        codesToHide.length &&
        entry.code_ids.filter((id) => codesToHide.indexOf(id) !== -1).length
      ) {
        return false;
      }

      // demographics
      const demographicIds: Set<Demographic['id']> = new Set();
      (entry.participant_ids || []).map((id) => {
        const participant = state.catalog.participants[`${id}`];
        if (participant !== undefined) {
          participant.demographic_ids.map((id) => demographicIds.add(id));
        }
      });
      if (
        demographicsToShow.length &&
        !Array.from(demographicIds).filter(
          (id) => demographicsToShow.indexOf(id) !== -1
        ).length
      ) {
        return false;
      } else if (
        demographicsToHide.length &&
        Array.from(demographicIds).filter(
          (id) => demographicsToHide.indexOf(id) !== -1
        ).length
      ) {
        return false;
      }

      return true;
    });
  },
  /**
   * Returns all entries, unfiltered. Used to get accurate counts for the Conversations page
   * @param state StoreState
   */
  getAllEntries: (state: StoreState): Entry[] => {
    return Object.values(state.catalog.entries);
  },
  getDisplayMode: (state: StoreState): DisplayMode => {
    return state.catalog.displayMode;
  },
  getLoadingMode: (state: StoreState): LoadingMode => {
    return state.catalog.loadingMode;
  },
  getError: (state: StoreState): Error | undefined => {
    return state.catalog.error;
  },
  getInternalCodes: (state: StoreState): Code[] => {
    return sortCodes(
      Object.values(state.catalog.codes).filter(
        (code) => code.code_type === 'internal'
      )
    );
  },
  getAllCodes: (state: StoreState): Code[] => {
    return sortCodes(Object.values(state.catalog.codes));
  },
  getFiltersQuery: (
    state: StoreState,
    filters: Filter[],
    filterJoin: FilterJoin
  ): string => {
    const allCodes = sortCodes(Object.values(state.catalog.codes));
    const demographics = sortCodes(Object.values(state.catalog.demographics));
    let excludeFromPortal = false;
    let excludeHasNone = false;
    const parentedFilters = allCodes.length
      ? filters.map((filter) => {
          if (
            [FilterName.AI_SUGGESTED_CODES, FilterName.APPLIED_CODES].includes(
              filter.filter_name
            )
          ) {
            // update values for parentage
            let valuesWithParentage = Array.from(
              new Set(
                filter.values.flatMap((v) =>
                  allCodes
                    ?.filter((c) => c.parentage?.includes(v as number))
                    .map((c) => c.id)
                )
              )
            );
            // check for and remove administrative codes
            if (
              valuesWithParentage.includes(ADMINISTRATIVE_CODES_ID) ||
              valuesWithParentage.includes(EXCLUDE_FROM_PORTAL_CODE_ID)
            ) {
              if (filter.operation === FilterOperation.HAS_NONE) {
                // set flag to true to hide exclude from portal
                excludeHasNone = true;
              }
              excludeFromPortal = true;
              valuesWithParentage = valuesWithParentage.filter((id) => id >= 0);
            }
            return { ...filter, values: valuesWithParentage };
          }
          if ([FilterName.SPEAKER_DEMOGRAPHICS].includes(filter.filter_name)) {
            // update values for parentage
            const valuesWithParentage = new Set(
              filter.values.flatMap((v) =>
                demographics
                  ?.filter((c) => c.parentage?.includes(v as number))
                  .map((c) => c.id)
              )
            );
            return { ...filter, values: Array.from(valuesWithParentage) };
          }
          return filter;
        })
      : filters;

    // add filter for Exclude from Portal
    if (excludeFromPortal) {
      parentedFilters.push({
        filter_name: 'EXCLUDED_FROM_PORTAL' as FilterName, // Cast because this filter is not a visible filter
        operation: FilterOperation.IS_EXACT,
        values: [excludeHasNone ? false : true],
      });
    }

    return parentedFilters
      ? parentedFilters
          .filter(
            (filter) =>
              filter.values.length ||
              valuelessOperations.includes(filter.operation)
          ) // remove filters that don't have a value if value is required
          .map((c) => `filters=${JSON.stringify(c)}`)
          .join('&') + `&filter_join=${filterJoin.toString().toUpperCase()}`
      : '';
  },
  getParticipants: (state: StoreState): Participant[] => {
    return Object.values(state.catalog.participants);
  },
  getParticipantsForConversation: (
    state: StoreState,
    conversation: Conversation
  ): Participant[] => {
    return Object.values(state.catalog.participants).filter((participant) => {
      return participant.conversation_id === conversation.id;
    });
  },
  getStructuralCodes: (state: StoreState): Code[] => {
    return sortCodes(
      Object.values(state.catalog.codes).filter(
        (code) => code.code_type === 'structural'
      )
    );
  },
  getThematicCodes: (state: StoreState): Code[] => {
    return sortCodes(
      Object.values(state.catalog.codes).filter(
        (code) => code.code_type === 'thematic'
      )
    );
  },
  getSelectedConversation: (state: StoreState): Conversation | undefined => {
    return state.catalog.conversations[
      `${state.catalog.selectedConversationId}`
    ];
  },
  getSelectedEntry: (state: StoreState): Entry | undefined => {
    return state.catalog.entries[`${state.catalog.selectedEntryId}`];
  },
  getSelectedParticipant: (state: StoreState): Participant | undefined => {
    return state.catalog.participants[`${state.catalog.selectedParticipantId}`];
  },
  isLoading: (state: StoreState): boolean => {
    return state.catalog.isLoading;
  },
  getHighlightSnippets: (
    state: StoreState,
    snippetIds: string[]
  ): Snippet[] => {
    const allSnippets = state.catalog.snippets;
    if (Object.keys(allSnippets).length === 0) {
      // Return with an empty list
      return [];
    }
    return snippetIds
      .map((id) => {
        const snippet = allSnippets[id];
        if (snippet) {
          return snippet;
        } else {
          return { id: -1 } as Snippet;
        }
      })
      .filter((s) => s.id !== -1);
  },
  getFilters: (state: StoreState): CatalogFilter[] => {
    return state.catalog.filters;
  },
  iAmAdminOrOwner: (state: StoreState): boolean =>
    !!state.auth.user.roles.filter(
      (role) =>
        role.organization_id === state.catalog.catalog?.organization_id &&
        (role.role_type.toLowerCase() ===
          OrganizationRole.admin.toLowerCase() ||
          role.role_type.toLowerCase() === OrganizationRole.owner.toLowerCase())
    ).length,
  getSensemakers: (state: StoreState): User[] => state.catalog.sensemakers,
  getSelectedFilters: (state: StoreState): Filter[] => {
    return state.catalog.selectedFilters;
  },
  getInitialFilter: (state: StoreState): Filter => {
    return state.catalog.initialFilter;
  },
  getFilterJoin: (state: StoreState): FilterJoin => {
    return state.catalog.filterJoin;
  },
};

export default selectors;
