import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Tooltip } from 'reactstrap';
import cx from 'classnames';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import ConfirmationModal from 'src/components/ConfirmationModal/ConfirmationModal';
import Button from 'src/components/core/Button/Button';
import adminSelectors from 'src/redux/admin/admin-selectors';
import { userTransaction } from 'src/redux/admin/admin-slice';
import { generateTransaction } from 'src/redux/redux-helpers';
import { StoreState } from 'src/redux/store';
import { UserTransactions } from 'src/types/admin';
import { User } from 'src/types/auth';
import { CommunityMember } from 'src/types/forum';
import { OrganizationRole } from 'src/types/organization';
import { fullName } from '../../Common/utils';

import styles from './RemoveMemberButton.module.scss';

interface RemoveMemberButtonProps {
  member: User | CommunityMember;
  useIcon?: boolean;
  communityTable?: boolean;
  classNames?: string;
  verboseLabel?: boolean;
}

const RemoveMemberButton = ({
  member,
  useIcon,
  communityTable,
  classNames,
  verboseLabel,
}: RemoveMemberButtonProps) => {
  const dispatch = useDispatch();
  const iAmAdminOrOwner = useSelector(adminSelectors.iAmAdminOrOwner);
  const { t } = useTranslation();
  const memberFullName = `${member.first_name || ''} ${member.last_name || ''}`;
  const [removeTooltip, setRemoveTooltip] = React.useState<boolean>(false);
  const [confirmationModal, setConfirmationModal] =
    React.useState<boolean>(false);
  const [transaction, setTransaction] = React.useState<UserTransactions>();
  const transactions = useSelector(adminSelectors.getUserTransactions);
  const organizationId = useSelector(adminSelectors.getOrganizationId);
  const activeCommunity = useSelector(adminSelectors.getActiveCommunity);
  const isOwner =
    useSelector((state) =>
      (state as StoreState).admin.users.filter(({ id }) => id === member.id)
    )[0]?.roles.filter(
      (role) =>
        role.organization_id === organizationId &&
        role.role_type.toLowerCase() === OrganizationRole.owner.toLowerCase()
    ).length ?? false;
  const [, setQuery] = useQueryParams({
    page: StringParam,
    userId: NumberParam,
  });

  React.useEffect(() => {
    let matchingTransaction: UserTransactions;
    if (transaction) {
      matchingTransaction = transactions.filter(
        (item) => item.id === transaction.id
      )[0];
      // TODO: Error Handling
    }
    return () => {
      if (matchingTransaction) {
        setConfirmationModal(false);
        setQuery({ userId: undefined, page: undefined });
      }
    };
  }, [transactions, transaction, setQuery]);

  const onDeleteClick = () => {
    const name = fullName(member);
    const communityName = activeCommunity?.name;

    const transaction = communityTable
      ? generateTransaction({
          type: 'remove_community_member',
          data: { id: member.id },
          toast: {
            onError: {
              content: (err) =>
                t('admin.remove_community_member_error_toast', {
                  name,
                  communityName,
                }),
              options: {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: false,
              },
            },
            onSuccess: {
              content: t('admin.remove_community_member_success_toast', {
                name,
                communityName,
              }),
              options: {
                position: toast.POSITION.BOTTOM_RIGHT,
              },
            },
          },
        })
      : generateTransaction({
          type: 'remove',
          data: { id: member.id },
        });

    setTransaction(transaction);
    dispatch(userTransaction(transaction));
  };

  const modalText = communityTable
    ? 'admin.tab_community_member_remove_tooltip'
    : 'admin.tab_team_member_remove_tooltip';

  const modalRemoveButtonLabel =
    verboseLabel && !communityTable
      ? t('admin.remove_org_member')
      : t('admin.remove_member');

  const buttonLabel =
    verboseLabel && !communityTable
      ? t('admin.remove_org_member')
      : t('common.remove');

  const RemoveConfirmationModal = (
    <ConfirmationModal
      isLoading={!!transaction}
      isOpen={confirmationModal}
      handleCloseModal={() => setConfirmationModal(false)}
      text={{
        description: t(modalText, {
          name: memberFullName,
        }),
        confirm: modalRemoveButtonLabel,
      }}
      confirm={onDeleteClick}
    />
  );

  const disabled = !iAmAdminOrOwner;

  //Return Logic
  if (isOwner) {
    return <></>;
  } else if (useIcon) {
    const tooltipText = t('admin.tab_team_member_remove_tooltip', {
      name: memberFullName,
    });

    return (
      <>
        <Button
          outline
          className={cx('shadow-none', classNames)}
          icon={['fas', 'trash']}
          id={`remove-team-member-${member.id}`}
          aria-label={t('admin.tab_team_member_remove_tooltip', {
            name: memberFullName,
          })}
          onClick={() => setConfirmationModal(true)}
          data-testid={`remove-team-member-${member.id}`}
          disabled={disabled}
        />
        {
          <Tooltip
            placement="bottom"
            hideArrow={true}
            isOpen={removeTooltip}
            innerClassName={cx(styles.tooltip)}
            target={`remove-team-member-${member.id}`}
            toggle={() => setRemoveTooltip((self) => !self)}
            data-testid={`tooltip-remove-member-${member.id}`}
          >
            {tooltipText}
          </Tooltip>
        }
        {RemoveConfirmationModal}
      </>
    );
  } else {
    const tooltipText = communityTable
      ? t('admin.tab_community_member_remove_tooltip', {
          name: memberFullName,
        })
      : disabled
      ? t('admin.tab_team_member_remove_not_admin_tooltip')
      : t('admin.tab_team_member_remove_tooltip', {
          name: memberFullName,
        });

    return (
      <>
        <div
          className="d-inline-block"
          id={`remove-team-member-${member.id}`}
          onMouseOver={() => {
            // needed to show popup on a disabled button
            setRemoveTooltip(true);
          }}
        >
          <Button
            className={cx(styles.removeButton, classNames)}
            onClick={() => setConfirmationModal(true)}
            data-testid="remove-member-button"
            disabled={disabled}
          >
            {buttonLabel}
          </Button>
        </div>
        {
          <Tooltip
            placement="bottom"
            hideArrow={true}
            isOpen={removeTooltip}
            innerClassName={cx(styles.tooltip)}
            target={`remove-team-member-${member.id}`}
            toggle={() => setRemoveTooltip((self) => !self)}
            data-testid={`tooltip-remove-member-${member.id}`}
          >
            {tooltipText}
          </Tooltip>
        }
        {RemoveConfirmationModal}
      </>
    );
  }
};

export default RemoveMemberButton;
